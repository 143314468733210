import React, { useEffect, useState } from 'react';
import './InstallPrompt.css';

const InstallPrompt = () => {
  const [showModal, setShowModal] = useState(false);
  const [installPromptEvent, setInstallPromptEvent] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault(); // Prevent the default browser prompt
      setInstallPromptEvent(event);

      const isPromptShown = localStorage.getItem('installPromptShown');

      if (!isPromptShown) {
        setShowModal(true); // Show the custom modal
      }
    };

    if ('serviceWorker' in navigator && window.matchMedia('(display-mode: standalone)').matches) {
      // Check if service worker is supported and the app is already installed
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('installPromptShown', 'true'); // Store flag indicating prompt has been shown
  };

  const handleOk = () => {
    if (installPromptEvent) {
      // Trigger the installation prompt provided by the browser
      installPromptEvent.prompt();

      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User installed the PWA');
        } else {
          console.log('User dismissed the PWA installation');
        }
      });
    }

    closeModal();
  };

  return (
    <>
      {showModal && (
        <div id="install-modal" className="prompt-modal">
          <div className="prompt-modal-content">
            <button className="x-button" onClick={closeModal}>
              <span>&times;</span>
            </button>
            <div className="prompt-modal-header">
              <h2>Προσθήκη στη Συσκευή</h2>
            </div>
            <div className="prompt-modal-buttons">
              <button onClick={closeModal}>Άκυρο</button>
              <button onClick={handleOk}>OK</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InstallPrompt;
